/* unplugin-vue-components disabled */"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _withScopeId = function (n) { return ((0, vue_2.pushScopeId)("data-v-02231c89"), n = n(), (0, vue_2.popScopeId)(), n); };
var _hoisted_1 = { class: "title" };
var vue_3 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'Home',
    setup: function (__props) {
        function greeter(name) {
            return "Hello, " + name;
        }
        ;
        (0, vue_3.onMounted)(function () {
        });
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, "PC端正在开发中，请使用手机打开本网站进行体验，谢谢！"));
        };
    }
});
