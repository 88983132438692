import axios from "@/plugins/axios.js";
import { ElMessage } from "element-plus";
export const getJobResult = async (job_id) => {
  let data = {
    job_id,
  };
  return await axios({
    method: "post",
    url: "jobs/poll-result",
    data: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getJobPos = async (job_id) => {
  let data = {
    job_id,
  };
  try {
    let response = await axios({
      method: "post",
      url: "jobs/get-job-position",
      data: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log(response);
    let number = response.data.idx;
    if (!number) number = 0;
    return number;
  } catch (error) {
    console.log(error);
    if (error.response.status === 400)
      ElMessage.error({ message: "job ID格式错误", plain: true });
    else ElMessage.error({ message: "获取任务出错", plain: true });
  }
};
