import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-074bc650"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "radio-group"
};
const _hoisted_2 = ["onClick"];
import { computed } from "vue";
export default {
  __name: 'RadioGroup',
  props: {
    selections: {
      type: Array
    },
    value: {
      type: String
    }
  },
  emits: ["update:value"],
  setup(__props, {
    emit: __emit
  }) {
    const props = __props;
    const emit = __emit;
    const nowValue = computed({
      get: () => {
        if (props.value) return props.value;else if (props.selections?.[0]) return props.selections[0];else return "";
      },
      set: val => emit("update:value", val)
    });
    const changeValue = val => {
      nowValue.value = val;
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.selections, selection => {
        return _openBlock(), _createElementBlock("div", {
          key: selection,
          onClick: $event => changeValue(selection),
          class: _normalizeClass(nowValue.value === selection ? 'active-selection' : 'normal-selection')
        }, _toDisplayString(selection), 11, _hoisted_2);
      }), 128))]);
    };
  }
};